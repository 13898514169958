<template>
  <div class="space-y-4">
    <asom-card>
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <h3 class="text-lg font-medium text-gray-900">
        Transaction Information
      </h3>
      <div class="grid sm:grid-cols-1 md:grid-cols-2" v-if="transactionData">
        <asom-form-field label="Transaction No">
          <p class="mt-1 font-semibold">{{ transactionData.transactionNo }}</p>
        </asom-form-field>
        <asom-form-field label="PSM" v-if="transactionData.psm">
          <p class="mt-1 font-semibold">{{ transactionData.psm.name }}</p>
        </asom-form-field>
        <asom-form-field label="Input Type">
          <p class="mt-1 font-semibold">
            {{ inputType(transactionData.inputType) }}
          </p>
        </asom-form-field>
        <asom-form-field v-if="showGTM" label="GTM/TUK">
          <p class="mt-1 font-semibold">
            {{ transactionData.gtm.accountName }}
          </p>
        </asom-form-field>
        <asom-form-field label="Input">
          <p class="mt-1 font-semibold">{{ transactionData.input }}</p>
        </asom-form-field>
        <asom-form-field label="Description">
          <p class="mt-1 font-semibold">{{ transactionData.description }}</p>
        </asom-form-field>
        <div></div>
        <div>
          <asom-form-field label="Waived (-)">
            <p class="mt-1 font-semibold">${{ transactionData.waived }}</p>
          </asom-form-field>
          <asom-form-field label="Payment (-)">
            <p class="mt-1 font-semibold">${{ transactionData.payment }}</p>
          </asom-form-field>
          <asom-form-field label="Receipt (+)">
            <p class="mt-1 font-semibold">${{ transactionData.receipt }}</p>
          </asom-form-field>
        </div>
        <asom-form-field
          v-if="showPassengerSignature"
          label="Passenger Signature"
        >
          <asom-signature-view
            :file-id="get(transactionData, 'passengerSignature.attachmentId')"
          />
        </asom-form-field>
        <asom-form-field
          class="col-span-2"
          v-if="selectedRecordAttachments.length > 0"
          label="Supporting Document"
        >
          <asom-upload-file-list
            :files="selectedRecordAttachments"
            disabled-remove
          />
        </asom-form-field>
      </div>
      <div class="flex justify-end space-x-4">
        <asom-button
          text="Back"
          variant="secondary"
          @click="() => this.$router.go(-1)"
        />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { getPSMTransactionDetails } from "../../../../services/cashManagement.service";

export default {
  name: "ViewManualRecord",
  data() {
    return {
      transactionData: null,
      isLoading: false,
      isSubmitting: false,
      error: null,
    };
  },
  computed: {
    selectedRecordAttachments() {
      if (get(this.transactionData, "attachments") !== null) {
        return get(this.transactionData, "attachments", []);
      } else return [];
    },
    showGTM() {
      if (get(this.transactionData, "gtm") !== null) {
        return [3, 4].includes(this.transactionData.inputType);
      } else return false;
    },
    showPassengerSignature() {
      if (get(this.transactionData, "passengerSignature") !== null) {
        return this.transactionData.inputType === 1;
      } else return false;
    },
  },
  mounted() {
    this.getPSMRecordDetails();
  },
  methods: {
    get,
    inputType(type) {
      if (type == 1) {
        return "Passenger name";
      } else if (type == 2) {
        return "CAN ID";
      } else if (type == 3) {
        return "Notebox No.";
      } else if (type == 4) {
        return "Coinbox No.";
      } else if (type == 5) {
        return "PSM Top-up";
      } else if (type == 6) {
        return "Others";
      } else {
        return "";
      }
    },
    async getPSMRecordDetails() {
      const result = await getPSMTransactionDetails({
        transactionId: get(this.$route.params, "transactionId"),
      });
      if (result.success) {
        this.setTransactionData(get(result.payload, "transaction", {}));
      }
    },
    setTransactionData(data) {
      this.transactionData = data;
    },
  },
};
</script>
